// Generated by Framer (214386f)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["PoUZJDVxp", "e9KFxaxZg"];

const variantClassNames = {e9KFxaxZg: "framer-v-1itg7s8", PoUZJDVxp: "framer-v-1owm9za"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {"Variant 1": "PoUZJDVxp", "Variant 2": "e9KFxaxZg"};

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; tap?: any }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "PoUZJDVxp", tap: PoM4YLkh3, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "PoUZJDVxp", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTapb9skyi = activeVariantCallback(async (...args) => {
if (PoM4YLkh3) {
const res = await PoM4YLkh3(...args);
if (res === false) return false;
}
setVariant("e9KFxaxZg")
})

const onTapfju2d3 = activeVariantCallback(async (...args) => {
if (PoM4YLkh3) {
const res = await PoM4YLkh3(...args);
if (res === false) return false;
}
setVariant("PoUZJDVxp")
})

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-QBjiw", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-1owm9za", className)} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"PoUZJDVxp"} onTap={onTapb9skyi} ref={ref} style={{...style}} {...addPropertyOverrides({e9KFxaxZg: {"data-framer-name": "Variant 2", onTap: onTapfju2d3}}, baseVariant, gestureVariant)}><motion.div className={"framer-n638q2"} layoutDependency={layoutDependency} layoutId={"yC0jiGF1r"}><motion.div className={"framer-1wz0jn"} layoutDependency={layoutDependency} layoutId={"LJVZFR4J2"} style={{backgroundColor: "rgb(118, 118, 127)", borderBottomLeftRadius: 30, borderBottomRightRadius: 30, borderTopLeftRadius: 30, borderTopRightRadius: 30, rotate: 0}} variants={{e9KFxaxZg: {rotate: 45}}}/><motion.div className={"framer-1iktw89"} layoutDependency={layoutDependency} layoutId={"YIlKJ9egC"} style={{backgroundColor: "rgb(118, 118, 127)", borderBottomLeftRadius: 30, borderBottomRightRadius: 30, borderTopLeftRadius: 30, borderTopRightRadius: 30, rotate: 0}} variants={{e9KFxaxZg: {rotate: -45}}}/></motion.div></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-QBjiw [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-QBjiw .framer-196qp2 { display: block; }", ".framer-QBjiw .framer-1owm9za { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 32px; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: 32px; }", ".framer-QBjiw .framer-n638q2 { flex: none; height: 14px; overflow: visible; position: relative; width: 20px; }", ".framer-QBjiw .framer-1wz0jn { flex: none; height: 3px; left: 0px; overflow: visible; position: absolute; right: 0px; top: 0px; }", ".framer-QBjiw .framer-1iktw89 { bottom: 0px; flex: none; height: 3px; left: 0px; overflow: visible; position: absolute; right: 0px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-QBjiw .framer-1owm9za { gap: 0px; } .framer-QBjiw .framer-1owm9za > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-QBjiw .framer-1owm9za > :first-child { margin-left: 0px; } .framer-QBjiw .framer-1owm9za > :last-child { margin-right: 0px; } }", ".framer-QBjiw.framer-v-1itg7s8 .framer-1owm9za { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 32px); }", ".framer-QBjiw.framer-v-1itg7s8 .framer-1wz0jn, .framer-QBjiw.framer-v-1itg7s8 .framer-1iktw89 { bottom: 6px; height: unset; left: unset; top: 5px; width: 20px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 32
 * @framerIntrinsicWidth 32
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"e9KFxaxZg":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"PoM4YLkh3":"tap"}
 */
const FramermYD5ua7ga: React.ComponentType<Props> = withCSS(Component, css, "framer-QBjiw") as typeof Component;
export default FramermYD5ua7ga;

FramermYD5ua7ga.displayName = "Icons/AnimatedMenuBurger";

FramermYD5ua7ga.defaultProps = {height: 32, width: 32};

addPropertyControls(FramermYD5ua7ga, {variant: {options: ["PoUZJDVxp", "e9KFxaxZg"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}, PoM4YLkh3: {title: "Tap", type: ControlType.EventHandler}} as any)

addFonts(FramermYD5ua7ga, [])